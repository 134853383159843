import axios from "@/utils/axios";

export const informationDetail = (data: any) =>
  axios.get(`/api/course/information/detail/${data.id}`);
export const selectClassifyList = () =>
  axios.get(`/api/course/information/selectPcClassifyList`);
export const listByPageInformation = (data: any) =>
  axios.get(`/api/course/information/listByPage`, { params: data });
export const selectHotspotList = (data: any) =>
  axios.get(`/api/course/information/selectHotspotList`, { params: data });
